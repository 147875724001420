.custom-button {
    padding: 10px 20px;
    font-size: 16px;
    color:var(--clr-white);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}
.btn-black {
    background-color:var(--clr-black);
}
.btn-red {
    background-color: var(--clr-red-600);
}
.btn-green {
    background-color: var(--clr-green-600);
}
.custom-button:hover {
    cursor: pointer;
}
