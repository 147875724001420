.completed-cancelled-rides-container {
    .ride-details-table {
        width: 100%;
        border-collapse: collapse;
    }

    .ride-details-table th,
    .ride-details-table td {
        border: 1px solid var(--clr-grey-250);
        padding: 8px;
        text-align: left;
    }

    .spacing-0 {
        .icon-button {
            margin: 0px !important;
            padding: 0px !important;
            border: none;
            background-color: transparent;
        }
    }
}