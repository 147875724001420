:root {
    --clr-black: #000;
    --clr-green-600: #00ff00;

    --clr-grey: grey;
    --clr-grey-200: #ccc;
    --clr-grey-250: #ddd;
    --clr-grey-300: #f0f0f0;
    --clr-grey-400: #f5f5f5;

    --clr-red-600: #ff0000;
    --clr-white: #fff;
}

.flex {
    display: flex;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.p-20 {
    padding: 20px;
}

.w100{
    width: 100%;
}

.cursor-pointer{
    cursor: pointer;
}