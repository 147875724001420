.header-container{
    position: relative;
}

.header-title {    
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    background-color: var(--clr-grey-300);
    text-align: center;
    padding: 10px;
}

.topRight{
    position: absolute;
    right: 0;
    margin-top: 5px;
}