.onboard-template-container{
    border: 1px solid var(--clr-grey-200);
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
    text-transform: capitalize;
    
    .image-styles{
        height: 250px;
        width: 250px;
    }


    .documents-styles{
        display: flex;
        flex-direction: row;
        overflow: scroll;
    }

    .documents-styles-item{
        margin: 2px;
    }

    button{
        padding: 6px;
        border-radius: 10px;
    }

    input, .rejection-submit-button{
        font-size: 1rem;
        padding: 10px;
        margin-top: 15px;
        margin-right: 10px;
        border-radius: 10px;
        border: 1px solid var(--clr-grey-200);
        margin-bottom: 5px;
    }

}