.ongoingRides-container {
    
    .ride-details-table {
        width: 100%;
        border-collapse: collapse;
    }

    .ride-details-table th,
    .ride-details-table td {
       border: 1px solid var(--clr-grey-200);
       text-align: center;
        padding: 8px;
        margin: auto;
    }

    .profile-pic-styles {
        border-radius: 100vw;
    }
}